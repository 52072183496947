import React, { useContext, useEffect, useState } from "react";
import TagManager from "react-gtm-module/dist/TagManager";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import { getCoursera } from "../../services/cursera";
import { getUmanoLink } from "../../services/umano";
import SpinnerCuadrado from "../helpers/SpinnerCuadrado";
import { getToken } from "../helpers/validations";
import Swal from "sweetalert2";
import { programasCoursera } from "../helpers/data";
import { addLogs } from "../../services/logs_proteccion";
import { getHolos } from "../../services/salud";

const useBotons = () => {
  const navigate = useNavigate();
  const { globalDataUser, globalInactivaSesion, globalEcosistemas } =
    useContext(AuthContext);
  const [cargaExterno, setCargaExterno] = useState(false);
  const {pathname} = useLocation()

  const [linkCoursera, setLinkCoursera] = useState(null)
  const [refreshCoursera, setRefreshCoursera] = useState(true)

  const callGTM = (ecosys, service, acc) => {
    window.dataLayer.push({
      event: `SBK_${ecosys}_${service}_BTN_${acc}`,
      action: `BTN_${acc}`,
    });
  };

  const gotoCreaCuenta = () => {
    navigate("/registro");
  };

  const activaCuentaBtn = (color = "", fondo = false) => {
    return (
      <button
        className={`activacuenta__btn`}
        onClick={gotoCreaCuenta}
        style={{ borderRadius: "7px" }}
      >
        Activa tu cuenta
      </button>
    );
  };

  const unirmeBtn = (selectedCard = {}, fondo = false) => {
    return (
      <button
        className={`unirme__btn`}
        onClick={() => {
          navigate(selectedCard.rutaexterna);
        }}
        style={{ borderRadius: "7px" }}
      >
        Quiero Saber Más
      </button>
    );
  };

  const verMasBtn = (color = "", fondo = false) => {
    return (
      <button
        className={`vermas__btn ${color} ${fondo ? "fondo" : ""}`}
        onClick={() => {
          navigate("/iniciasesion");
        }}
      >
        Ver más
      </button>
    );
  };

  const ingresarBtn = (color = "", fondo = false) => {
    return (
      <button
        className={`vermas__btn ${color} ${fondo ? "fondo" : ""}`}
        // onClick={gotoLogin}
      >
        Ingresar
      </button>
    );
  };

  const gotoCoursera = (ecosys, service, acc, eco = "") => {
    //Inicio tgm
    callGTM(ecosys, service, acc);
    //Fin tgm
    
    addLogs("coursera", "entro a link externo coursera");
    setRefreshCoursera(true)
  };

  const gotoFractalia = (ecosys, service, acc) => {
    callGTM(ecosys, service, acc);

    addLogs("protecciononline", "entro a link externo fractalia");
    window.open("https://ciberseguridad-mexico.app.onlineassist.me", "_blank");
  };

  const gotoAlertasBuro = (ecosys, service, acc) => {
    callGTM(ecosys, service, acc);

    addLogs("alertasburo", "entro a link externo alertas buro");
    window.open("https://wbc2.burodecredito.com.mx:343/AlertasBuro", "_blank");
  };

  const openModalTelefono = (link) => {
    let titulo = "";
    let descripcion = "";

    if (link.includes("asistencia-medica")) {
      titulo = "Asistiéndote desde la palma de tu mano";
      descripcion =
        "Las 24 (veinticuatro) horas del día y los 365 (trescientos sesenta y cinco) días del año.";
      addLogs(
        "Ingreso_Portal_UMANO",
        "Presiona botón para realizar llamada a Asistencia médica",
        ""
      );
    } else if (link.includes("red-salud")) {
      titulo = "Red de servicios de salud";
      descripcion =
        "Ponemos a tu disposición una red de servicios de salud al lugar donde te encuentres.";
      addLogs(
        "Ingreso_Portal_UMANO",
        "Presiona botón para realizar llamada a red de servicios de salud",
        ""
      );
    }

    Swal.fire({
      title: "<strong>" + titulo + "</strong>",
      html:
        '<a href="tel:800 8001 2402" style="text-decoration: none;color:inherit;" class="llamar-telefono-mobile"><h2 class="mt-3" style="color: #15246a;border: 0.5px solid #15246a;max-width: 280px;width: 100%;margin: auto;padding: 15px;border-radius: 3px;font-size: 17px">' +
        "Llama al 800 801 2402, opc. 3</h2></a>" +
        '<br/><br/><p class="mt-3" style="font-size: 17px !important;">' +
        descripcion +
        "</p>",
      confirmButtonText:
        '<div style="border-radius: 0px !important;width:90px;height:20px;padding:4px">OK</div>',
      confirmButtonColor: "#15246a",
      allowOutsideClick: false,
    });
  };

  const GoToUmano = (ecosys, service, acc) => {
    callGTM(ecosys, service, acc);
    try {
      addLogs(
        "Ingreso_Portal_UMANO",
        "Ingresa a UMANO para evaluación médica",
        ""
      );
      getUmanoLink(getToken()).then(([json, status]) => {
        if (status === 401) {
          globalInactivaSesion();
          return;
        }
        if (json?.includes("/")) {
          window.open(json, "_blank", "noopener,noreferrer");
          return;
        }
        Swal.fire({
          icon: "error",
          title: "Inconvenientes en la conexión",
          text: "Volver a intentar en unos instantes.",
        });
      });
    } catch (error) {
      // console.log(error)
    }
  };

  const registraTAG = (
    ecosys,
    service,
    acc,
    textoBoton,
    descripcion,
    tituloTag,
    pagina,
    accion
  ) => {
    // console.log("taGGGsss");
    addLogs(pagina, accion, "");
    if (ecosys && service && acc) {
      callGTM(ecosys, service, acc);
    }
  };

  const abriBlank = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
    return;
  };

  const goToHolos = async () => {
    let data = await getHolos(getToken());
    Swal.fire({icon: 'info',title: 'Cargando...',allowOutsideClick: false,showConfirmButton: false});
    if(data?.codigo === 0){
      window.open(data.data, "_blank");
      Swal.close();
      addLogs('Bienestar integral', 'Salto exitoso a Holos', '')
      return
    }
    Swal.fire({
      icon: "error",
      title: 'Error',
      text: 'Error en salto a Holos'
    });
  }

  setInterval(() => {
    setRefreshCoursera(true)
  }, 60000)

  useEffect(() => {
    if (!refreshCoursera) return

    let ecosistema = ''
    if (pathname.includes('/emprendimiento/coursera')) {
      ecosistema = 'Emprendimiento'
    }
    else if (pathname.includes('/empleabilidad/coursera')) {
      ecosistema = 'Empleabilidad'
    }
    else if (pathname.includes('/proteccion-financiera/coursera')) {
      ecosistema = 'Seguro de Protección financiera'
    }
    else if (pathname.includes('/vidaplus/coursera')) {
      ecosistema = 'Vital Plus'
    }
    else return

    let dataCoursera = [];
    let ecosistemaPrograma = globalEcosistemas.find(
      (e) => e.eco === ecosistema && e.active === true
    );

    try {
      dataCoursera = programasCoursera.find(
        (pc) => pc.id === ecosistemaPrograma.idProgramaCoursera
      );
      getCoursera(
        globalDataUser.globalUsuId,
        getToken(),
        ecosistema,
        dataCoursera
      ).then(([nuevaRuta, status]) => {

        if (status === 401) {
          globalInactivaSesion();
          return;
        }
        if (nuevaRuta?.includes("/")) {
          setLinkCoursera(nuevaRuta)
          setRefreshCoursera(false)
          return;
        }
        Swal.fire({
          icon: "error",
          title: "Inconvenientes en la conexión",
          text: "Volver a intentar en unos instantes.",
        });
      });
    } catch (error) {
      // console.log(error)
    }
  }, [pathname, refreshCoursera])

  const iniciaProcesoBtn = (
    ecosys,
    service,
    acc,
    botonActivo = true,
    textoBoton = "",
    color = "#cf141b",
    fondo = false,
    link = "/ecosistema",
    descripcion = "",
    tituloTag = "",
    ecosistema = "",
    pagina = "",
    accion = ""
  ) => {
    return (
      <>
        {botonActivo && (
          <>
            {link.includes("externo") ? (
              <>
                {cargaExterno && <SpinnerCuadrado />}
                <a
                  href={linkCoursera}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="iniciaProceso__btn"
                  style={{
                    backgroundColor: `${
                      fondo
                        ? `${color === "" ? "#cf141b" : color}`
                        : "transparent"
                    }`,
                    color: `${fondo ? "#fff" : `${color}`}`,
                    border: `${
                      fondo
                        ? `${color === "" ? "#cf141b" : color}`
                        : "transparent"
                    }`,
                  }}
                  onClick={() => {
                    gotoCoursera(ecosys, service, acc, ecosistema);
                  }}
                >
                  {textoBoton}
                </a>
              </>
            ) : ( link.includes("tel:")) ?
              <a
                href={link}
                className="iniciaProceso__btn llamar-telefono-mobile"
                style={{ width: '33rem' }}
                onClick={() => addLogs(pagina, accion, ecosistema)}
                // style={{ backgroundColor: `${fondo ? `${color === '' ? '#cf141b' : color}` : 'transparent'}`, color: `${fondo ? '#fff' : `${color}`}` }}
              >
                {textoBoton}
              </a>
            : (
              <>
                {link.includes("fitnesspass") ? (
                  <div
                    className="iniciaProceso__btn"
                    // style={{ backgroundColor: `${fondo ? `${color === '' ? '#cf141b' : color}` : 'transparent'}`, color: `${fondo ? '#fff' : `${color}`}` }}
                    onClick={() => {
                      abriBlank(link);
                    }}
                  >
                    {textoBoton}
                  </div>
                ) : (
                  <>
                    {link.includes("fractalia") ? (
                      <div
                        className="iniciaProceso__btn"
                        // style={{ backgroundColor: `${fondo ? `${color === '' ? '#cf141b' : color}` : 'transparent'}`, color: `${fondo ? '#fff' : `${color}`}` }}
                        onClick={() => {
                          gotoFractalia(ecosys, service, acc);
                        }}
                      >
                        {textoBoton}
                      </div>
                    ) : (
                      <>
                        {link.includes("buro") ? (
                          <div
                            className="iniciaProceso__btn"
                            // style={{ backgroundColor: `${fondo ? `${color === '' ? '#cf141b' : color}` : 'transparent'}`, color: `${fondo ? '#fff' : `${color}`}` }}
                            onClick={() => {
                              gotoAlertasBuro(ecosys, service, acc);
                            }}
                          >
                            {textoBoton}
                          </div>
                        ) : (
                          <>
                            {link.includes("asistencia-medica") ||
                            link.includes("red-salud") ? (
                              <div
                                className="vidaplus__btn"
                                // style={{ backgroundColor: `${fondo ? `${color === '' ? '#cf141b' : color}` : 'transparent'}`, color: `${fondo ? '#fff' : `${color}`}` }}
                                onClick={() => {
                                  openModalTelefono(link);
                                }}
                              >
                                {textoBoton}
                              </div>
                            ) : (
                              <>
                                {link.includes("evaluacion-medica") ? (
                                  <div
                                    className="vidaplus__btn"
                                    // style={{ backgroundColor: `${fondo ? `${color === '' ? '#cf141b' : color}` : 'transparent'}`, color: `${fondo ? '#fff' : `${color}`}` }}
                                    onClick={() => {
                                      GoToUmano(ecosys, service, acc);
                                    }}
                                  >
                                    {textoBoton}
                                  </div>
                                ) : (
                                  <>
                                    {link.includes("vidaplus-coursera") ? (
                                      <a
                                        href={linkCoursera}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className="vidaplus__btn"
                                        // style={{ backgroundColor: `${fondo ? `${color === '' ? '#cf141b' : color}` : 'transparent'}`, color: `${fondo ? '#fff' : `${color}`}` }}
                                        onClick={() => {
                                          gotoCoursera(
                                            ecosys,
                                            service,
                                            acc,
                                            "Vital Plus"
                                          );
                                        }}
                                      >
                                        {textoBoton}
                                      </a>
                                    ) : (
                                      <>
                                        {link.includes(
                                          "/mi-salud-financiera"
                                        ) ? (
                                          <NavLink
                                            onClick={() =>
                                              registraTAG(
                                                ecosys,
                                                service,
                                                acc,
                                                textoBoton,
                                                descripcion,
                                                tituloTag,
                                                pagina,
                                                accion
                                              )
                                            }
                                            to={link}
                                            className={`pfelemental__btn`}
                                            // style={{ backgroundColor: `${fondo ? `${color === '' ? '#cf141b' : color}` : ''}`, color: `${fondo ? '#fff' : `${color}`}` }}
                                            style={{
                                              backgroundColor: `var(--naranjo-rosado)`,
                                            }}
                                          >
                                            {textoBoton}
                                          </NavLink>
                                        ) : (
                                          <>
                                            {link.includes("holos") ? (
                                              <>
                                                {cargaExterno && <SpinnerCuadrado />}
                                                <div
                                                  className="iniciaProceso__btn"
                                                  style={{
                                                    backgroundColor: `${
                                                      fondo
                                                        ? `${color === "" ? "#cf141b" : color}`
                                                        : "transparent"
                                                    }`,
                                                    color: `${fondo ? "#fff" : `${color}`}`,
                                                    border: `${
                                                      fondo
                                                        ? `${color === "" ? "#cf141b" : color}`
                                                        : "transparent"
                                                    }`,
                                                  }}
                                                  onClick={() => {
                                                    goToHolos();
                                                  }}
                                                >
                                                  {textoBoton}
                                                </div>
                                              </>
                                            ) : (
                                            <NavLink
                                              onClick={() =>
                                                registraTAG(
                                                  ecosys,
                                                  service,
                                                  acc,
                                                  textoBoton,
                                                  descripcion,
                                                  tituloTag,
                                                  pagina,
                                                  accion
                                                )
                                              }
                                              to={link}
                                              className={`iniciaProceso__btn`}
                                              // style={{ backgroundColor: `${fondo ? `${color === '' ? '#cf141b' : color}` : ''}`, color: `${fondo ? '#fff' : `${color}`}` }}
                                              style={{
                                                backgroundColor: `var(--rojo)`,
                                              }}
                                            >
                                              {textoBoton}
                                            </NavLink>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const iniciaProcesoBtnAccion = (
    botonActivo = true,
    textoBoton = "",
    color = "#cf141b",
    fondo = false,
    link = "/ecosistema",
    acccion
  ) => {
    return (
      <>
        {botonActivo && (
          <>
            {link.includes("https") ? (
              <>
                <a
                  className={`iniciaProceso__btn`}
                  // style={{ backgroundColor: `${fondo ? `${color === '' ? '#cf141b' : color}` : ''}`, color: `${fondo ? '#fff' : `${color}`}` }}
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {textoBoton}
                </a>
              </>
            ) : (
              <>
                <div
                  className={`iniciaProceso__btn`}
                  // style={{ backgroundColor: `${fondo ? `${color === '' ? '#cf141b' : color}` : ''}`, color: `${fondo ? '#fff' : `${color}`}` }}
                  onClick={acccion}
                >
                  {textoBoton}
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  };

  const loginBtn = (color = "", fondo = false) => {
    return (
      <button className={`unirme__btn ${color} ${fondo ? "fondo" : ""}`}>
        Inicia sesión
      </button>
    );
  };

  const comenzarBtn = (color = "", fondo = false, label = "Comenzar") => {
    return (
      <button
        className={`comenzar__btn ${color} ${fondo ? "fondo" : ""}`}
        // onClick={gotoLogin}
      >
        {label}
      </button>
    );
  };

  return {
    unirmeBtn,
    activaCuentaBtn,
    verMasBtn,
    loginBtn,
    ingresarBtn,
    iniciaProcesoBtn,
    comenzarBtn,
    iniciaProcesoBtnAccion,
  };
};

export default useBotons;
